/* eslint-disable no-unused-vars */
import React from "react";
import { nanoid } from "nanoid";
import http from "@apiServices/http";
import urls from "@apiServices/apiUrls";
import { getCookies, getFeedDetails, setCookie } from "@utils/util";
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore/lite";
// import { feedPageSize } from "@components/pages/feedPage/constants";
import { getBookmarkedArticles } from "@utils/articleUtils";
import Feed from "@components/pages/feedPage/feedDetailNew";
import { feedPageSize } from "@components/pages/feedPage/constants";
import { serverErrorLogger } from "@analytics/ErrorLogger/server";
import { getAllCategoriesWebStories, getWebStories } from "@apiServices/apis";
import firestoreService from "@firestoreServices/firestoreServices";
import { captureException } from "@sentry/nextjs";

const Home = ({
  selectedPreferenceData = [],
  followingPreferencesData = [],
  savedKeywordsData = [],
  trending = true,
  feedPageType = "isTrending",
  suggestedPreferencesData = [],
  stories = [],
  feedSummaryDetailData = [],
  discoverMoreTagsData = [],
  trendingTopicsData = [],
  secondaryDiscoverMoreTagsData = [],
  trendingArticlesData = [],
  allPrefs = "",
  isSignUpState = false,
  userLikeArticlesData = [],
  savedKeywordInitialData = [],
  userBookmarkArticlesData = [],
  preferenceIdsData = [],
  pageError = false,
  excludeTrendingFeedIds = [],
  isTrendingFeedFetchedOnServer = false,
  nextPage = 0,
  googleWebStoriesData = [],
  showNewswraponPageLoad,
  newswrapData = null,
  feedDetail = []
}: any) => {
  return (
    <Feed
      selectedPreferenceData={selectedPreferenceData}
      followingPreferencesData={followingPreferencesData}
      savedKeywordsData={savedKeywordsData}
      trending={trending}
      feedPageType={feedPageType}
      googleWebStoriesData={googleWebStoriesData}
      nextPage={nextPage}
      suggestedPreferencesData={suggestedPreferencesData}
      stories={stories}
      feedSummaryDetailData={feedSummaryDetailData}
      excludeTrendingFeedIds={excludeTrendingFeedIds}
      isTrendingFeedFetchedOnServer={isTrendingFeedFetchedOnServer}
      discoverMoreTagsData={discoverMoreTagsData}
      trendingTopicsData={trendingTopicsData}
      secondaryDiscoverMoreTagsData={secondaryDiscoverMoreTagsData}
      trendingArticlesData={trendingArticlesData}
      allPrefs={allPrefs}
      isSignUpState={isSignUpState}
      userLikeArticlesData={userLikeArticlesData}
      savedKeywordInitialData={savedKeywordInitialData}
      userBookmarkArticlesData={userBookmarkArticlesData}
      preferenceIdsData={preferenceIdsData}
      pageError={pageError}
      showNewswraponPageLoad={showNewswraponPageLoad}
      newswrapDetails={newswrapData}
      feedDetail={feedDetail}
    />
  );
};

export async function getServerSideProps({ req, res, query, resolvedUrl }) {
  try {
    const cookies = getCookies({ req, res });
    let isTrending = true;
    let feedPage = "isTrending";
    let isSignUp = !!cookies["isSignUp"];
    let pathname = resolvedUrl;
    let selectedPreferences = [];
    let followingPreferences = [];
    let savedKeywords = [];
    let suggestedPreferences = [];
    let userLikeArticles = [],
      savedKeywordInitial = [],
      userBookmarkArticles = [],
      preferenceIds = [];

    // const db = getFirestore();

    // search params
    const queryParams = query;
    const queryTagIds = [];
    // queryParams.tagIds ? queryParams.tagIds.split(",") : [];
    const querySavedKeyword = queryParams.savedKeyword;
    let preferenceSelected = [];
    // let userData;
    let docRef;
    const firestore = new firestoreService();
    let userId = cookies["techminis_u_id"];
    let userData = await firestore.fetchUserData(userId, true, { req, res });

    // if (userId) {
    // docRef = doc(db, "users", userId);
    // const docSnap = userId ? await getDoc(docRef) : null;

    // if (docSnap?.exists() && docSnap.data()) {
    // userData = docSnap.data();
    preferenceSelected = userData?.preferences || [];
    selectedPreferences = userData?.preferences || [];
    userLikeArticles = userData?.likes || [];

    savedKeywordInitial = userData?.savedKeywords || [];
    savedKeywords = userData?.savedKeywords || [];

    preferenceIds = userData?.preferences || [];
    userBookmarkArticles = getBookmarkedArticles({ userFirestoreData: userData }) || [];
    // }
    // } else {
    //   userId = nanoid();
    //   setCookie("id", userId, { req, res });
    //   userData = {
    //     id: userId,
    //     platform: "web",
    //     lastUpdatedDate: new Date(),
    //     preferences: [],
    //     savedKeywords: []
    //   };

    //   docRef = doc(db, "users", userId);
    //   await setDoc(docRef, userData, { merge: true });
    // }
    const selectedTags = [];
    let prefs = "";

    await http
      .get(urls.suggestions, {
        headers: {
          uid: userData.id
        }
      })
      .then(async (data: any) => {
        data.map((item, index) => {
          if (index === 0) {
            item["isSelected"] = true;
          } else {
            item["isSelected"] = false;
          }

          item.tags.map((el) => {
            el["isSelected"] = false;
            el["channelName"] = item.channelName;
          });
        });
        prefs = JSON.stringify(data);

        data.map((item) => {
          const size = 5;
          const temp = [];
          item.tags.map((tag) => {
            if (queryTagIds.includes(tag.id)) {
              tag.isSelected = true;
              selectedTags.push(tag);
            } else {
              if (!preferenceSelected.includes(tag.id)) {
                temp.push(tag);
              } else {
                tag.isSelected = true;

                selectedTags.push(tag);
              }
              // tag["type"] = "preference";
            }
          });
          // selected tagIds to be updated in firestore

          // firestore.addUserPreferences(selectedTagIds);

          const res = temp.reduce((acc, curr, i) => {
            if (!(i % size)) {
              // if index is 0 or can be divided by the `size`...
              acc.push(temp.slice(i, i + size)); // ..push a chunk of the original array to the accumulator
            }
            return acc;
          }, []);
          item["tagDetail"] = res;

          item["tagDetail"].map((el) => {
            el.map((tag) => {
              if (preferenceSelected.includes(tag.id)) {
                tag["isSelected"] = true;
              } else {
                tag["isSelected"] = false;
              }
            });
          });
        });
        const dataTemp = [];
        data = data.map((el) => {
          if (el.tagDetail.length > 0) {
            dataTemp.push(el);
          }
        });
        const selectedTagIds = selectedTags.map((item) => item.id);
        if (queryTagIds.length) {
          userData = {
            ...userData,
            preferences: [...new Set(selectedTagIds)]
          };
        }
        if (querySavedKeyword) {
          const savedKeywords = userData?.savedKeywords || [];
          if (!savedKeywords.includes(querySavedKeyword)) {
            userData = {
              ...userData,
              savedKeywords: [...savedKeywords, querySavedKeyword]
            };
          }
        }
        // if (queryTagIds.length || querySavedKeyword) {
        //   await setDoc(docRef, userData);
        // }

        // if (selectedTagIds.length > 0 || userData?.savedKeywords?.length > 0) {
        //   isTrending = false;
        //   feedPage = "myFeed";
        //   // localStorage.setItem("feedPage", "myFeed");
        //   setCookie("feedPage", "myFeed", { req, res });
        // } else {
        isTrending = true;
        feedPage = "isTrending";
        // }

        suggestedPreferences = dataTemp;
        followingPreferences = selectedTags;
      })
      .catch((error) => {
        captureException(error);
      });
    // Show Trending feed on return from SSR page
    if (query.showTrendingFeed) {
      isTrending = true;
      feedPage = "isTrending";
    }
    // let followingTagId = undefined,
    // apiSavedKeywords = [],
    // isReturnFromCustomizeYourFeed = false;

    // let recent = false,

    let followingTagId = undefined,
      apiSavedKeywords = [],
      isReturnFromCustomizeYourFeed = false;

    let recent = false;

    const fetchFeedData = async ({
      page = 0,
      showOlderContent = false,
      articleIds = undefined
    }) => {
      const userFirestoreData = userData;

      if (page == 0) {
        followingTagId =
          userFirestoreData?.preferences?.length > 0 ? userFirestoreData?.preferences : [];
        apiSavedKeywords = userFirestoreData?.savedKeywords || [];
        if (query.savedKeyword && !isReturnFromCustomizeYourFeed) {
          apiSavedKeywords = [...new Set([...apiSavedKeywords, query.savedKeyword])];
        }

        isSignUp = !!cookies["isSignUp"];
      }

      return http
        .post(
          urls.trending,
          {
            tags: followingTagId?.length > 0 ? followingTagId : undefined,
            preferencesSkipped:
              !!!apiSavedKeywords?.length && !!!followingTagId?.length ? true : undefined,
            savedKeywords: apiSavedKeywords || [],
            articlesToExclude: [
              ...(userFirestoreData?.report || []),
              ...(userFirestoreData?.notInterested || []),
              ...(getBookmarkedArticles({ userFirestoreData }) || [])
            ],
            recent: recent || !showOlderContent,
            page: page,
            size: feedPageSize,
            articleIds: articleIds
          },
          {
            headers: {
              uid: userData.id
            }
          }
        )
        .then((res: any) => {
          feedDetail = res || [];
        })
        .catch((error) => {
          pageError = true;
          captureException(error);
        });
    };

    let feedDetail = [],
      feedDetailWithoutNewswrapData = [],
      pageError = false,
      excludeTrendingFeedIds = [];

    const fetchTrendingFeedData = async () => {
      return http
        .get(urls.trendingSSR, {
          headers: {
            uid: userData.id
          }
        })
        .then((res: any) => {
          feedDetail = res || [];
          res.map((el) => {
            excludeTrendingFeedIds.push(el.id);
          });
        })
        .catch((error) => {
          captureException(error);
        });
    };

    let trendingArticles = [];
    // const fetchTrendingAricles = async () => {
    //   return http
    //     .post(
    //       urls.trendingArticles,
    //       { tags: userData?.preferences || [] },
    //       { headers: { uid: userId } }
    //     )
    //     .then((res: any) => {
    //       trendingArticles = res;
    //     })
    //     .catch(() => {
    //     });
    // };
    // await fetchTrendingAricles();
    let discoverMoreTags = [],
      trendingTopics = [],
      secondaryDiscoverMoreTags = [],
      googleWebStories = [];

    const fetchDiscoverMoreTags = async () => {
      let tagIds = "";
      (userData?.preferences || []).map((item, index) => {
        if (index === 0) {
          tagIds += `${item}`;
        } else {
          tagIds += `,${item}`;
        }
      });

      let url = urls.discoverMoreTags({ tagIds });
      return http
        .get(url, { headers: { uid: userData.id } })
        .then((res: any) => {
          discoverMoreTags = res;
        })
        .catch((error) => {
          captureException(error);
        })
        .finally(() => {});
    };

    const fetchTrendingTopics = async () => {
      let url = urls.trendingDiscoverMoreTags;
      return http
        .get(url, { headers: { uid: userData.id } })
        .then((res: any) => {
          if (res.primary && res.primary.length) {
            trendingTopics = res.primary.map((i) => [i.slug, i.slugDisplayName]);
          }
          if (res.secondary && res.secondary.length) {
            secondaryDiscoverMoreTags = res.secondary.map((i) => [i.slug, i.slugDisplayName]);
          }
        })
        .catch((error) => {
          captureException(error);
        })
        .finally(() => {});
    };
    // await fetchDiscoverMoreTags();

    if (isTrending) {
      await Promise.allSettled([
        queryParams.articleIds
          ? fetchFeedData({
              page: 0,
              articleIds: queryParams.articleIds ? queryParams.articleIds.split(",") : undefined
            })
          : fetchTrendingFeedData(),
        fetchDiscoverMoreTags(),
        fetchTrendingTopics()
      ]);
    } else {
      return {
        redirect: {
          destination: "/my-feeds",
          permanent: false
        }
      };

      // await Promise.allSettled([
      //   fetchFeedData({
      //     page: 0,
      //     articleIds: queryParams.articleIds ? queryParams.articleIds.split(",") : undefined
      //   }),
      //   fetchTrendingAricles(),
      //   fetchDiscoverMoreTags(),
      //   fetchTrendingTopics()
      // ]);
    }
    if (Array.isArray(feedDetail) && feedDetail.length === 0) {
      await fetchTrendingTopics();
    }

    const stories = [];
    // fetch stories for widget
    // const data = await getWebStories();
    // data.webStories?.nodes?.map((el) => {
    //   stories.push(el);
    // });

    // newswrap circular widget data
    let newswrapData = null;

    //check if news_wrap data exists
    // const newswrapTemp = feedDetail.find((el) => el.newsBiteType === "NEWS_WRAP");
    // if (feedDetail.length && newswrapTemp) {
    //   newswrapData = { ...newswrapTemp };

    //   // remove the newswrap data from the feed details
    //   const temp1 = feedDetail.filter((item) => item.id !== newswrapTemp.id);
    //   feedDetailWithoutNewswrapData = [...temp1];
    // } else {
    feedDetailWithoutNewswrapData = [...feedDetail];
    // }

    // updated feed detail without newswrap data, newswrap data set in another object
    const feedTemp = JSON.parse(JSON.stringify(feedDetailWithoutNewswrapData));

    const params = {
      feedTemp,
      isTrending,
      hasDiscoverMoreTags: discoverMoreTags.length,
      hasTrendingTopics: trendingTopics.length,
      hasSecondaryDiscoverMoreTags: secondaryDiscoverMoreTags.length,
      isSignUp,
      pathname,
      hasTrendingArticles: trendingArticles.length
    };
    const updatedFeed = getFeedDetails(params);

    return {
      props: {
        isSignUpState: false, //isSignUp
        excludeTrendingFeedIds: excludeTrendingFeedIds,
        allPrefs: prefs,
        preferenceIdsData: [], //preferenceIds
        suggestedPreferencesData: [], //suggestedPreferences
        selectedPreferenceData: [], //selectedPreferences
        followingPreferencesData: [], //followingPreferences
        savedKeywordsData: [], //savedKeywords
        savedKeywordInitialData: [], //savedKeywordInitial
        userLikeArticlesData: [],
        userBookmarkArticlesData: userBookmarkArticles,
        trending: isTrending,
        feedPageType: feedPage,
        stories,
        feedSummaryDetailData: updatedFeed,
        nextPage: queryParams.articleIds ? 1 : 0,
        isTrendingFeedFetchedOnServer: excludeTrendingFeedIds.length > 0,
        discoverMoreTagsData: discoverMoreTags,
        trendingTopicsData: trendingTopics,
        secondaryDiscoverMoreTagsData: secondaryDiscoverMoreTags,
        trendingArticlesData: trendingArticles,
        googleWebStoriesData: googleWebStories,
        pageError,
        feedDetail,
        newswrapData,
        showNewswraponPageLoad:
          feedDetail.length > 0 &&
          feedDetail[0].newsBiteType === "NEWS_WRAP" &&
          queryParams?.articleIds
            ? true
            : false
      }
    };
  } catch (err) {
    serverErrorLogger({ err, req, screenName: "trending" });
    return {
      props: { pageError: true }
    };
  }
}

export default Home;
